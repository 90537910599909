export const containerStyles = {
  display: 'column',
  flexFlow: 'column wrap',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  marginBottom: 6,
};

export const listStyles = {
  display: 'flex',
  color: 'grays.60',
  flexFlow: 'row nowrap',
  listStyleType: 'none',
  marginLeft: 0,
  paddingLeft: 0,
  '>*': {
    // marginBottom: 1,
  },
  ':nth-last-of-type(1)': {
    marginBottom: 4,
  },
};

export const dateStyles = {
  marginRight: 4,
  minWidth: '80px',
  fontSize: ['18px', 3, 3],
  lineHeight: 3,
};
