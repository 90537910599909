import PropTypes from "prop-types";

export const propTypes = {
	listTitle: PropTypes.string,
	dataType: PropTypes.string,
	data: PropTypes.exact({
		edges: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				date: PropTypes.string,
				slug: PropTypes.string,
			})
		),
	}),
};

export const defaultProps = {
	listTitle: "Your Posts",
	listType: "notes",
	data: {
		edges: [
			{
				date: "03.20.20",
				title: "Typeface as a metaphor for design systems",
				slug: "/",
			},
			{
				date: "03.20.20",
				title: "Typeface as a metaphor for design systems",
				slug: "/",
			},
			{
				date: "03.20.20",
				title: "Typeface as a metaphor for design systems",
				slug: "",
			},
			{
				date: "03.20.20",
				title: "Typeface as a metaphor for design systems",
				slug: "/",
			},
		],
	},

	CTA: {
		title: "see all posts",
		slug: "/",
	},
};
