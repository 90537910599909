/** @jsx jsx */
import { jsx, Text, Link, useColorMode } from "theme-ui";
import React from "react";
import { gridStyles, bodyColumn } from "../styles/shared.styles";
import { graphql, Link as GatsbyLink } from "gatsby";
import List from "../components/home/List";
import SEO from "../components/seo";

import { socialLinks } from "../utils/routes";

var { useEffect } = React;

const Index = (props) => {
	var { allMdx, site } = props.data;

	return (
		<>
			<SEO />
			<main role="main" sx={{ ...gridStyles, marginTop: 5 }}>
				<div sx={bodyColumn}>
					<Text
						variant="display"
						sx={{
							marginBottom: 4,
						}}
						as="h1"
					>
						Hello, I&apos;m Jeremy
					</Text>
					<Text variant="articleBody" as="p" sx={{ marginBottom: 2 }}>
						I’m a product designer, code tinkerer, and creative
						experimenter leading design over at{" "}
						<Link
							variant="primary"
							href="https://www.gatsbyjs.org/"
							target="_blank"
							rel="noreferrer"
						>
							Gatsby JS,
						</Link>{" "}
						and a southerner currently living life in Seattle.
						Before Gatsby, I worked on large-scale design systems at{" "}
						<Link
							variant="primary"
							href="https://www.microsoft.com/design"
							target="_blank"
							rel="noreferrer"
						>
							Microsoft
						</Link>
						. I have a special passion for typography, design
						history, React, and, of course, Gatsby. Let’s be
						internet buddies, or grab a coffee.
					</Text>

					<ul
						sx={{
							listStyleType: "none",
							marginLeft: 0,
							paddingLeft: 0,
							li: {
								marginBottom: 3,
							},
							marginBottom: 5,
						}}
					>
						<li>
							<Link
								href={socialLinks[0].path}
								variant="primary"
								target="_blank"
								rel="noreferrer"
							>
								@jeremystubarnes on twitter
							</Link>
						</li>
						<li>
							<GatsbyLink
								to="/about"
								sx={(props) => props.links.primary}
							>
								More about me
							</GatsbyLink>
						</li>
					</ul>

					<List
						data={allMdx}
						listType="posts"
						listTitle="Recent Blog Posts"
						CTA={{ title: "View all posts", slug: "/blog" }}
					/>

					<List
						data={allMdx}
						listType="notes"
						listTitle="Recent Notes"
						CTA={{ title: "View all notes", slug: "/notes" }}
					/>

					<List
						data={allMdx}
						listType="oss"
						listTitle="Recent OSS projects"
						CTA={{ title: "View all projects", slug: "/oss" }}
					/>
					<List
						data={allMdx}
						listType="work-highlights"
						listTitle="Work Highlights"
						CTA={{
							title: "More about me",
							slug: "/about",
						}}
					/>
				</div>
			</main>
		</>
	);
};

export default Index;

export const query = graphql`
	query MyQuery {
		allMdx(sort: { fields: frontmatter___date, order: DESC }) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						description
						date
						externalSlug
					}
					fileAbsolutePath
					body
					internal {
						type
					}
				}
			}
		}
		site {
			siteMetadata {
				image
			}
		}
	}
`;

// `;

// import ReactMarkdown from "react-markdown";
// var CustomRenderers = {
// 	paragraph: (props) => (
// 		<Text {...props} variant="body" as="p" sx={{ marginBottom: 2 }} />
// 	),
// 	link: (props) => <Link variant="Primary" {...props} />,
// };
// <ReactMarkdown
// source={node.internal.content}
// renderers={CustomRenderers}
// />

{
	/* <List
	data={props.data}
	listType="notes"
	listTitle="Recent Notes"
	CTA={{ title: "View all notes", slug: "/notes" }}
/> */
}
